import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";
import ImageGallery from "react-image-gallery"
const images = [
    {
        original: require('../images/rodney-smith/rodney-smith-1.png'),
        thumbnail: require('../images/rodney-smith/rodney-smith-1.png')
    },
    {
        original: require('../images/rodney-smith/rodney-smith-2.png'),
        thumbnail: require('../images/rodney-smith/rodney-smith-2.png')
    },
    {
        original: require('../images/rodney-smith/rodney-smith-3.png'),
        thumbnail: require('../images/rodney-smith/rodney-smith-3.png')
    },
    {
        original: require('../images/rodney-smith/rodney-smith-4.png'),
        thumbnail: require('../images/rodney-smith/rodney-smith-4.png')
    }
]

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Website for Rodney Smith</h1>
            <h4>August - October 2019</h4>
            <p>
                I worked on this project with a team of web developers to build a website for the late photographer Rodney Smith. To build it, we used a combination of Wordpress and custom CSS/HTML to build the website to design specifications, and implementing the features they wanted. In the project, I learned a lot of what it's like to work on a team of programmers to build a real product, and ended up creating a great looking website. You can view the website <a rel="noopener noreferrer"  href="https://rodneysmith.com/" target="_blank">here</a> and screenshots of the website below:
            </p>
            <ImageGallery showFullscreenButton={false} showPlayButton={false} items={images}></ImageGallery>
        </ProjectPage>
    </div>)
}

export default IndexPage
